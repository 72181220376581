$(() => {
    $("#conf_select").select2({
        language: "zh-CN",
        placeholder: "请输入活动名称、缩写或者id",
        ajax: {
            url: "/api/search_conference",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return { q: params.term };
            },
            processResults: function (data) {
                return { results: data };
            },
            cache: true
        },
        minimumInputLength: 2,
        escapeMarkup: function (markup) { return markup; },
        language: {
            inputTooShort:function(n){
                return "最少输入"+(n.minimum-n.input.length)+"个字符"
            }
        },
    });
    $('#conf_select').on('change', (e) => {
        $('#conf_form').attr('action', '/ccp_' + e.currentTarget.value);
    });
    $("#organ_select").select2({
        language: "zh-CN",
        placeholder: "请输入机构名称",
        ajax: {
            url: "/api/search_iv_institution",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return { q: params.term };
            },
            processResults: function (data) {
                if (data) {
                    for (const key in data) {
                        data[key].id = data[key].institution_id;
                    }
                }
                return { results: data };
            },
            cache: true
        },
        minimumInputLength: 2,
        escapeMarkup: function (markup) { return markup; },
    });
    $('#organ_select').on('change', (e) => {
        $('#conf_form').attr('action', 'iv/' + e.currentTarget.value);
    });
});
